import axios from '@/libs/axios'

class DataService {
    getAll(page) {
        return axios.get("/orders/moving/active?page=" + page);
    }

    getAllInActive(page) {
        return axios.get("/orders/moving/inactive?page=" + page);
    }

    get(id) {
        return axios.get(`/orders/${id}`);
    }
    getOrderLogs(id) {
        return axios.get(`/orders/moving/${id}/logs`);
    }
    getOrderCount() {
        return axios.get(`/orders/moving/active/count`);
    }
    getOrderInActiveCount() {
        return axios.get(`/orders/moving/inactive/count`);
    }
    changeStatus(id) {
        return axios.get(`/changeCustomersStatus/${id}`)
    }
}
export default new DataService();